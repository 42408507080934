<mat-card class="container-form mat-elevation-z0">
  <div *ngIf="!isInfo">
    <h3>Usuarios</h3>
    <div class="dashboard" fxLayout="row wrap">
      <mat-form-field appearance="standard">
        <mat-label>Filtrar</mat-label>
        <input matInput (keyup)="applyFilter($event)" #input />
      </mat-form-field>

      <div class="example-container mat-elevation-z8">
        <mat-table [dataSource]="dataSource" matSort>
          <!-- ID Column -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Nombre
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Nombre">
              {{ row.name }}
            </mat-cell>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Correo
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Email">
              {{ row.email }}
            </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="phone">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Teléfono
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Phone">
              {{ row.phone }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="validate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Validado
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="validate">
              {{ row.validate === 1 ? 'Sí' : 'No' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a class="edit-button" mat-button (click)="openUser(element)"><mat-icon>remove_red_eye</mat-icon></a>
              <a class="remove-button" mat-button (click)="remove(element)"><mat-icon>delete</mat-icon></a>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
        </mat-table>

      </div>
    </div>
  </div>
  <mat-paginator id="paginator" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>


  <div *ngIf="isInfo" class="margin-info">
    <button mat-button class="go-back-button" (click)="goBack()">
      <mat-icon class="add-icon">arrow_back_ios</mat-icon>
    </button>
    <a class="title"><strong>Información del usuario</strong></a>
    <div fxLayout="row wrap" class="content">
      <div class="user-info" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>1º Apellido</mat-label>

            <input type="text" matInput disabled [value]="userSelected.lastNameOne" />
            <!-- <mat-icon matSuffix>title</mat-icon> -->
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>2º Apellido</mat-label>

            <input type="text" matInput disabled [value]="userSelected.lastNameTwo" />
            <!-- <mat-icon matSuffix>title</mat-icon> -->
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Nombre</mat-label>

            <input type="text" matInput disabled [value]="userSelected.name" />
            <!-- <mat-icon matSuffix>title</mat-icon> -->
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Email</mat-label>

            <input type="text" matInput disabled [value]="userSelected.email" />
            <!-- <mat-icon matSuffix>title</mat-icon> -->
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Teléfono móvil</mat-label>

            <input type="text" matInput disabled [value]="userSelected.phone" />
            <!-- <mat-icon matSuffix>title</mat-icon> -->
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Tipo de licencia</mat-label>

            <input type="text" matInput disabled [value]="userSelected.licenseType" />
            <!-- <mat-icon matSuffix>title</mat-icon> -->
          </mat-form-field>
        </p>

      </div>
      <div class="user-info" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Numero de licencia</mat-label>

            <input type="text" matInput disabled [value]="userSelected.licenseNumber" />
            <!-- <mat-icon matSuffix>title</mat-icon> -->
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>País de residencia</mat-label>

            <input type="text" matInput disabled [value]="userSelected.country" />
            <!-- <mat-icon matSuffix>title</mat-icon> -->
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>Linkedin</mat-label>

            <input type="text" matInput disabled [value]="userSelected.linkedin" />
            <!-- <mat-icon matSuffix>title</mat-icon> -->
          </mat-form-field>
        </p>

        <p>
          <mat-form-field appearance="outline" [style.width.px]="300">
            <mat-label>DNI</mat-label>

            <input type="text" matInput disabled [value]="userSelected.business" />
            <!-- <mat-icon matSuffix>title</mat-icon> -->
          </mat-form-field>
        </p>
      </div>
    </div>
    <div class="validated">
      <mat-checkbox class="second-check" color="primary" (change)="toggle()"
        [checked]="userSelected.validate === 1">Validado</mat-checkbox>
    </div>
    <div class="validated-button">
      <button class="send" (click)="resendRegisterMail()" mat-button color="success">
        Reenviar mail de registro
      </button>
    </div>
    <div class="validated-button">
      <button class="send" (click)="sendValidateMail()" mat-button color="success">
        Reenviar mail de validación
      </button>
    </div>
    <div class="validated-button">
      <button class="send" (click)="updateValidate()" mat-button color="success">
        Actualizar
      </button>
    </div>
    <div class="spinner" *ngIf="isSpinner">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
    <div class="spinner" *ngIf="isSubmited">
      <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
        <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round"
          stroke-linejoin="round">
          <path class="circle"
            d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z" />
          <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" />
        </g>
      </svg>
    </div>
  </div>
</mat-card>