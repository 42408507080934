<div *ngIf="isLoaded">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
     <ckeditor [editor]="Editor" 
                  formControlName="paragraph1"
                  [config]="editorConfig"></ckeditor>
        <mat-error *ngIf="formGroup.get('paragraph1')?.errors?.['required']">
            Párrafo obligatorio.
        </mat-error>

        <div class="action" style="margin-top:25px">
            <div class="button">
                <button class="send" type="submit" mat-button color="success">
                    Actualizar
                </button>
            </div>
            <div class="spinner">
                <mat-spinner *ngIf="isSpinner" [diameter]="30"></mat-spinner>
            </div>
            <div class="spinner" *ngIf="isSubmited">
                <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                  <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <path class="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"/>
                    <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621"/>
                  </g>
                </svg>
              </div>
        </div>
    </form>
</div>