import { E } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as e from 'express';
import { AdminData, AdminService } from 'src/app/_services/admin.service';
import { EmailService } from 'src/app/_services/email.service';
import { UserData, UsersService } from 'src/app/_services/users.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';

interface LoginFormInput {
  email: string;
  password: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  @Inject(MAT_DIALOG_DATA) public data: any;

  isLost: boolean = false;
  isSpinner: boolean = false;
  formGroup!: UntypedFormGroup;
  dialogRef!: MatDialogRef<any>;
    hidePassword: boolean = true;


  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private adminService: AdminService,
    private userService: UsersService,
    public dialog: MatDialog,
    private emailService: EmailService
  ) {}

  async ngOnInit(): Promise<void> {
    this.formGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  get registerFormControl() {
    return this.formGroup.controls;
  }

  goToRegister(): void {
    this.router.navigate(['/membership-registration']);
  }
  togglePasswordVisibility(): void {
    this.hidePassword = !this.hidePassword;
  }

  async resetPassword(): Promise<void> {
    const regexResult = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      this.formGroup.value.email
    );
    if (!regexResult) {
      this.isLost = true;
      setTimeout(() => {
        this.isLost = false;
      }, 2000);
    } else {
      (
        await this.userService.changeLostPassword(this.formGroup.value.email)
      ).subscribe(async (user) => {
        if (user === null) {
          Swal.fire({
            title: 'El usuario introducido no existe',
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#011d35',
          });
        } else {
          Swal.fire({
            title: 'Reestablecer contraseña',
            text: 'Hemos enviado un email a la dirección introducida con las indicaciones para reestablecer la contraseña',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#011d35',
          }).then(() => {
            window.location.href = environment.basePath;
          });
        }
      });
    }
  }

  onSubmit(input: LoginFormInput): void {
    if (this.formGroup.valid) {
      this.isSpinner = true;
      setTimeout(async () => {
        (await this.adminService.loginAdmin(input)).subscribe(
          async ({ token, object }) => {
            if (object !== null) {
              sessionStorage.setItem('token', token);
              sessionStorage.setItem('adminSession', '' + object.id);
              this.router.navigate(['/dashboard']);
            } else {
              (await this.userService.loginUser(input)).subscribe(
                ({ token, object }) => {
                  console.log('entra, obejct, ', object);
                 // alert("hello");
                  if (object !== null) {
                    console.log('entra al if, ', object);
                    if (object.lostPassword === 1) {
                      sessionStorage.setItem('tempId', '' + object.id);
                      this.isSpinner = false;
                      this.openDialog();
                    } else if (object.validate === 0) {
                      Swal.fire({
                        title: '¡Su solicitud está en proceso!',
                        text: 'Muchas gracias por unirte a nosotros, en breve un administrador comprobará tus datos y se pondrá en contacto contigo para indicarte los pasos a seguir para completar tu registro en asociación LMA España.',
                        icon: 'info',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#011d35',
                      }).then(() => {
                        window.location.href = environment.basePath;
                      });
                    } else {
                      sessionStorage.setItem('token', token);
                      sessionStorage.setItem('userSession', '' + object.id);
                      window.location.href = environment.basePath;
                    }
                  } else {
                    console.log('entra al else, ', object);

                    Swal.fire({
                      title: 'El usuario y contraseña introducidos no existen',
                      icon: 'error',
                      confirmButtonText: 'OK',
                      confirmButtonColor: '#011d35',
                    });
                    this.isSpinner = false;
                  }
                }
              );
            }
          }
        );
      }, 500);
    }
  }

  private async openDialog(): Promise<void> {
    this.dialog.open(DialogChangePassword);
  }
}

@Component({
  templateUrl: './dialog.html',
  styleUrls: ['./dialog.css'],
})
export class DialogChangePassword {
  id: string = '';
  constructor(
    public dialogRef: MatDialogRef<DialogChangePassword>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UsersService
  ) {
    this.id = sessionStorage.getItem('tempId')!;
  }

  async onSubmitChangePassword(): Promise<void> {
    document.getElementById('is-null')!.style.display = 'none';
    document.getElementById('is-bigger')!.style.display = 'none';
    document.getElementById('is-different')!.style.display = 'none';

    const pass = (document.getElementById('pass') as HTMLInputElement).value;
    const repeatPass = (
      document.getElementById('repeat-pass') as HTMLInputElement
    ).value;
    if (pass === '' || repeatPass === '') {
      document.getElementById('is-null')!.style.display = 'block';
    } else if (pass !== repeatPass) {
      document.getElementById('is-different')!.style.display = 'block';
    } else if (pass.length < 8 || repeatPass.length < 8) {
      document.getElementById('is-bigger')!.style.display = 'block';
    } else {
      (
        await this.userService.changePassword({ id: +this.id, password: pass })
      ).subscribe(async (response) => {
        sessionStorage.removeItem('tempId');
        Swal.fire({
          title: '¡Contraseña actualizada con éxito!',
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: '#011d35',
        }).then(() => {
          window.location.href = environment.basePath;
        });
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
