import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  GestionService,
  PrivacyConsent,
} from 'src/app/_services/gestion.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-form-privacy',
  templateUrl: './form-privacy.component.html',
  styleUrls: ['./form-privacy.component.css'],
})
export class FormPrivacyComponent implements OnInit {
  isSubmited: boolean = false;
  isSpinner: boolean = false;
  isLoaded: boolean = false;
  formGroup!: UntypedFormGroup;
  formValue: PrivacyConsent[] = [];
  public Editor: any = ClassicEditor;
    public editorConfig:any = {
    height: '400px' // Set the desired height here
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private gestionService: GestionService
  ) {}

  async ngOnInit(): Promise<void> {
    (await this.gestionService.getPrivacy()).subscribe((data) => {
      this.formValue = data;

      this.formGroup = this.formBuilder.group({
        paragraph1: [this.formValue[0].paragraph1]
      });
      this.isLoaded = true;
    });
  }

  async onSubmit(input: PrivacyConsent): Promise<void> {
    if (this.formGroup.valid) {
      this.isSpinner = true;
      input.id = this.formValue[0].id;
      (await this.gestionService.setPrivacy(input)).subscribe((data) => {
        this.formGroup = this.formBuilder.group({
          paragraph1: [this.formGroup.value.paragraph1]
        });
        this.isSpinner = false;
        this.isSubmited = true;
        setTimeout(() => {
          this.isSubmited = false;
        }, 2000);
      });
    }
  }
}
